import * as R from 'ramda';
import { init } from '@sentry/browser';

/*************
 *   TYPES   *
 *************/

declare var __GIT_COMMIT_VERSION__: string;
declare var __DEPLOYMENT_ENV__: string;

/***************
 *   HELPERS   *
 ***************/

const shouldInit = R.anyPass([R.equals('uat'), R.equals('prod')])(__DEPLOYMENT_ENV__);

(() => {
  if (shouldInit) {
    init({
      dsn: 'https://c6c7f4ca86664b87b1b737ee98015810@sentry.io/1476856',
      release: __GIT_COMMIT_VERSION__,
      environment: __DEPLOYMENT_ENV__
    });
  }
})();
